<template>
  <div >
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%">
      <el-table-column prop="id" label="订单号"/>
      <el-table-column prop="type" label="业务类型">
          <template slot-scope="scope">
          <span>{{ scope.row.type==1?'入金':'出金' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="金额(USD)"/>
      <el-table-column prop="ratio" label="汇率">
        <template slot-scope="scope">
          <span>{{ scope.row.realExRate+scope.row.channelFee+scope.row.otherFee }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="payType" label="支付方式">
        <template slot-scope="scope">
          <span>{{ parseType(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="审核状态">
        <template slot-scope="scope">
          <span>{{ parseStatus(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import { parseDepositStatus,parseWithdrawStatus,parseCommiType,parseCurrencyType,parsePayType,parseOutType } from '@/views/pt/ib/utils/index'
export default {
  mixins: [initData],
  props:{
     custId: {
      type: String,
      required: true
    },
    startTime: {
      type: String,
      default: ''
    },
    endTime: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters([
      'commiStatusOptions',
      'payTypeOptions'
    ])
  },
  watch:{
    startTime( newData,oldData){
        console.log(this.startTime)
        this.init()
    }
  },
  created() {
   if (this.payTypeOptions == null) {
      this.$store.dispatch("GetPayType");
    }
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    parseCommiType,
    parseCurrencyType,
    parsePayType,
    parseOutType,
    parseDepositStatus,
    parseWithdrawStatus,
    parseType(data){
      if(data.type==1) {
        return parsePayType(data.payType)
      }else {
        return parseOutType(data.payType)
      }
    },
    parseStatus(data){
      if(data.type==1) {
        return parseDepositStatus(data.status)
      }else {
        return parseWithdrawStatus(data.status)
      }
    },
    beforeInit() {
      this.url = '/crm/tCustDepositWithdraw'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort}
      if (this.custId!=null && this.custId!='') { this.params['login'] = this.custId }
      if (this.startTime!=null && this.startTime!='') { this.params['startTime'] = this.startTime }
      if (this.endTime!=null && this.endTime!='') { this.params['endTime'] = this.endTime }
      return true
    }
  }
}
</script>

<style scoped>

</style>
