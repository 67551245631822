<template>
  <div class="app-container main">
    <div
        class="account_data"
        v-loading="loading"
        :data="data"
    >
      <div class="item">
        <label class="label">ID</label>
        <span>{{ accountInfo.id }}</span>
      </div>
      <div class="item">
        <label class="label">账户</label>
        <span>{{ accountInfo.login }}</span>
      </div>
      <div class="item">
        <label class="label">结算货币</label>
<!--        1.美分显示USC,美金显示USD 2.数据不用换算,与MT一致-->
        <span>{{accountInfo.currency}}</span>
      </div>
      <div class="item">
        <label class="label">客户经理</label>
        <span>{{ accountInfo.managerName }}</span>
      </div>
      <div class="item">
        <label class="label">账户组</label>
        <span>{{ accountInfo.accType }}</span>
      </div>
      <div class="item">
        <label class="label">余额</label>
        <span>{{ accountInfo.balance }}</span>
      </div>
      <div class="item">
        <label class="label">信用额</label>
        <span>{{ accountInfo.credit }}</span>
      </div>
      <div class="item">
        <label class="label">浮动盈亏</label>
        <span>{{ accountInfo.floatingProfit }}</span>
      </div>
      <div class="item">
        <label class="label">净值</label>
        <span>{{ accountInfo.equity }}</span>
      </div>
      <div class="item">
        <label class="label">已用保证金</label>
        <span>{{ accountInfo.margin }}</span>
      </div>
      <div class="item">
        <label class="label">可用保证金</label>
        <span>{{ accountInfo.marginFree }}</span>
      </div>
      <div class="item">
        <label class="label">保证金比例</label>
        <span>{{ accountInfo.marginLevel }}</span>
      </div>
      <div class="item">
        <label class="label">创建时间</label>
        <span class="time">{{ parseTime(accountInfo.createTime) }}</span>
      </div>
    </div>
    <div class="info">
      <div class="account_record">
        <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
          <el-tab-pane label="交易记录" name="first">
            <tradeRecord :custId="login" :server-id="serverId" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import { parseTime } from "@/utils/index";
import tradeRecord from "@/components/customer/accountmgr/tradeRecord";
import { mapGetters } from "vuex";
import { initData } from "@/api/data";
import initData1 from "@/mixins/initData";
export default {
  components: {
    tradeRecord,
  },
  mixins: [initData1],
  created() {
    if (this.mtGroups == null) {
      this.$store.dispatch("GetMtGroups");
    }
    if (this.categorys == null) {
      this.$store.dispatch("GetCategorys");
    }

    this.login = this.$route.query.login;
    this.serverId = this.$route.query.serverId;
    this.dwCurrTypeOptions = this.wTypeOptions;
    this.init();
  },
  data() {
    return {
      dwLoading: false,
      dwDialog: false,
      cusLoading: false,
      accountInfo: {},
      cusOptions: "",
      dwForm: {
        id: this.custId,
        operType: 1,
        dwType: 1,
        amount: 0,
        remark: "",
        sendEmail: true
      },
      creForm: {},
      levForm: {
        id: this.custId,
        leverage: "",
        sendEmail: true
      },
      sup_this: this,
      custId: 0,
      data: "",
      activeName: "first",
      dwTypeOptions: [
        { key: 1, display_name: "出金" },
        { key: 2, display_name: "入金" }
      ],
      dTypeOptions: [
        { key: 1, display_name: "任务入金" },
        { key: 2, display_name: "返佣入金" },
        { key: 3, display_name: "任务转账入金" },
        { key: 4, display_name: "其他类型入金" }
      ],
      wTypeOptions: [
        { key: 1, display_name: "任务出金" },
        { key: 2, display_name: "任务转账出金" },
        { key: 3, display_name: "其他类型出金" }
      ],
      dwCurrTypeOptions: null,

      serverId: -1, // 在交易历史模块中接口加上这个字段
    };
  },
  computed: {
    ...mapGetters(["mtGroups"]),

  },
  watch: {
    "dwForm.operType": {
      handler(newVal, oldVal) {
        if (newVal == 2) {
          this.dwCurrTypeOptions = this.dTypeOptions;
        } else {
          this.dwCurrTypeOptions = this.wTypeOptions;
        }
      },
      deep: true
    },
    data(news, olds) {
      this.accountInfo = news[0];
    }
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "crm/getTCustMtaccountsAndCustInfos";
      const sort = "";
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
        mt4Info: this.login,
      };
      return true;
    },
    getCus(query) {
      if (query != "") {
        this.cusLoading = true;
        const url = "crm/tCustInfo";
        const params = { basicInfo: query };
        initData(url, params)
          .then(res => {
            this.cusOptions = res.content;
            setTimeout(() => {
              this.cusLoading = false;
            }, this.time);
            resolve(res);
          })
          .catch(err => {
            this.cusLoading = false;
            reject(err);
          });
      }
    },
    showDialog(oper) {
      switch (oper) {
        case 1:
          this.dwDialog = true;
          break;
      }
    },
    handleClick(tab, event) {},
  }
};
</script>

<style scoped>
.account_data {
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: rgb(100, 149, 230);
}
.item {
  flex: 1;
  box-sizing: border-box;
  color: #ffffff;
}
.item .label {
  color: #ffffff;
  display: block;
  font-size: 16px;
  max-width: 100%;
  margin-bottom: 5px;
  cursor: default;
}
.item span{
  color: #ffffff;
  display: block;
  font-size: 14px;
  max-width: 100%;
  cursor: default;
}
.account_info{
  background-color:rgba(0,0,0,0.1);
  margin: 20px 0;
  height: 300px;
}
.title {
  display: inline-block;
  margin: 20px 0 20px 20px;
  font-size: 24px;
  font-weight: 700;
}
.span{
  font-size: 14px;
  font-weight: bold;
  margin: 0 20px 0 20px;
}
.account_button{
  position: relative;
  top: 10px;
}
</style>
